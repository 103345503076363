import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, Pipe, inject, importProvidersFrom, NgModule, makeEnvironmentProviders } from '@angular/core';
import * as i1 from '@angular/common/http';
import * as i2 from '@ngrx/store';
import { createAction, props, createReducer, on, createFeatureSelector, createSelector, Store, StoreModule, provideState } from '@ngrx/store';
import { createEffect, ofType, Actions, EffectsModule, provideEffects } from '@ngrx/effects';
import { switchMap, map, catchError, tap, filter, take } from 'rxjs/operators';
import { of } from 'rxjs';

/**
 * This is not a real service, but it looks like it from the outside.
 * It's just an InjectionTToken used to import the config object, provided from the outside
 */
const DictionaryConfigService = new InjectionToken('DictionaryConfig');
class LanguageService {
  get _apiUrl() {
    if (!this.config.apiUrl) return `${this.BASE_URL || ''}api/dictionary`;
    if (this.isAbsolute(this.config.apiUrl)) {
      return this.config.apiUrl;
    }
    return `${this.BASE_URL || ''}${this.config.apiUrl}`;
  }
  constructor(Http, BASE_URL, config, store) {
    this.Http = Http;
    this.BASE_URL = BASE_URL;
    this.config = config;
    this.store = store;
    this.dictionary = {};
    this.currentLanguage = 'nl';
    if (!this.BASE_URL || !this.config) {
      this.currentLanguage = 'nl';
    }
    const domain = this.config.mapping.find(a => this.BASE_URL?.indexOf(a.domainPart) >= 0);
    if (domain) {
      this.currentLanguage = domain.language;
    } else {
      this.currentLanguage = 'nl';
    }
  }
  loadDictionary() {
    return this.Http.get(this._apiUrl);
  }
  getLanguage() {
    return this.currentLanguage;
  }
  init() {}
  isAbsolute(url) {
    var pattern = /^https?:\/\//i;
    return pattern.test(url);
  }
  static {
    this.ɵfac = function LanguageService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LanguageService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject('BASE_URL', 8), i0.ɵɵinject(DictionaryConfigService), i0.ɵɵinject(i2.Store));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LanguageService,
      factory: LanguageService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LanguageService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: ['BASE_URL']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DictionaryConfigService]
    }]
  }, {
    type: i2.Store
  }], null);
})();
class FromDictionaryPipe {
  constructor(langService) {
    this.langService = langService;
  }
  transform(value, returnPlaceholder = true) {
    if (!value) {
      return value;
    }
    if (!this.langService.dictionary || !this.langService.dictionary[value.toUpperCase()]) {
      const notFoundLabel = returnPlaceholder ? 'TEXT NOT FOUND FOR: "' + value + '"' : '';
      console.info(notFoundLabel);
      return notFoundLabel;
    }
    const val = this.langService.dictionary[value.toUpperCase()][this.langService.getLanguage()];
    if (val) {
      return val;
    }
    const notFoundLabel = returnPlaceholder ? `TEXT '${value}' NOT FILLED FOR: "${this.langService.getLanguage()}"` : '';
    console.info(notFoundLabel);
    return notFoundLabel;
  }
  static {
    this.ɵfac = function FromDictionaryPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FromDictionaryPipe)(i0.ɵɵdirectiveInject(LanguageService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "fromDictionary",
      type: FromDictionaryPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FromDictionaryPipe, [{
    type: Pipe,
    args: [{
      name: 'fromDictionary',
      standalone: true
    }]
  }], () => [{
    type: LanguageService
  }], null);
})();

/***
 * Example: 'pressroom-snackbar-title'
            | fromDictionary
            | setValueDict
            : [
                { key: 'countProject', value: projectCount },
                { key: 'countFiles', value: fileCount }
              ]
 * ***/
class SetValueToDictionaryPipe {
  transform(value, placeholders) {
    let text = value;
    placeholders.forEach(item => {
      const reg = `{${item.key}}`;
      text = text.replace(reg, item.value);
    });
    return text;
  }
  static {
    this.ɵfac = function SetValueToDictionaryPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SetValueToDictionaryPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "setValueDict",
      type: SetValueToDictionaryPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SetValueToDictionaryPipe, [{
    type: Pipe,
    args: [{
      name: 'setValueDict',
      standalone: true
    }]
  }], null, null);
})();
const LoadDictionary = createAction('[Dictionary] Load Dictionary');
const LoadDictionaryEntriesSuccess = createAction('[Dictionary] Load Dictionary Entries Success', props());
const LoadDictionarySuccess = createAction('[Dictionary] Load Dictionary Success', props());
const LoadDictionaryFail = createAction('[Dictionary] Load Dictionary Fail', props());
const initialState = {
  entities: {},
  loaded: false,
  loading: false
};
const DictionaryReducer = createReducer(initialState, on(LoadDictionarySuccess, state => {
  return {
    ...state,
    loading: true
  };
}), on(LoadDictionaryEntriesSuccess, (state, {
  entries
}) => {
  const entities = entries.reduce((dictEntries, entry) => {
    return {
      ...dictEntries,
      [entry.placeholder.toUpperCase()]: entry
    };
  }, {
    ...state.entities
  });
  return {
    ...state,
    entities
  };
}), on(LoadDictionarySuccess, (state, {
  entries
}) => {
  return {
    ...state,
    loading: false,
    loaded: true
  };
}), on(LoadDictionaryFail, (state, {
  error
}) => {
  return {
    ...state,
    loading: false
  };
}));
const getDictionaryEntities$1 = state => state.entities;
const getDictionaryLoading$1 = state => state.loading;
const getDictionaryLoaded$1 = state => state.loaded;
const reducers = {
  entries: DictionaryReducer
};
const getDictionaryState = createFeatureSelector('dictionary');
const loadDictionary = createEffect((actions$ = inject(Actions), langService = inject(LanguageService)) => {
  return actions$.pipe(ofType(LoadDictionary), switchMap(() => langService.loadDictionary().pipe(map(entries => LoadDictionaryEntriesSuccess({
    entries
  })), catchError(error => of(LoadDictionaryFail({
    error
  }))))));
}, {
  functional: true
});
const loadDictionaryEntriesSuccess = createEffect((actions$ = inject(Actions), langService = inject(LanguageService)) => {
  return actions$.pipe(ofType(LoadDictionaryEntriesSuccess), tap(({
    entries
  }) => {
    const entities = entries.reduce((dictEntries, entry) => {
      return {
        ...dictEntries,
        [entry.placeholder.toUpperCase()]: entry
      };
    }, {});
    langService.dictionary = entities;
  }), map(({
    entries
  }) => LoadDictionarySuccess({
    entries
  })));
}, {
  functional: true
});
const effects = {
  loadDictionary,
  loadDictionaryEntriesSuccess
};
const getAllDictionaryState = createSelector(getDictionaryState, state => state.entries);
const getDictionaryEntities = createSelector(getAllDictionaryState, getDictionaryEntities$1);
const getDictionaryLoaded = createSelector(getAllDictionaryState, getDictionaryLoaded$1);
const getDictionaryLoading = createSelector(getAllDictionaryState, getDictionaryLoading$1);
const DictionaryGuard = () => {
  const store = inject(Store);
  return store.select(getDictionaryLoaded).pipe(tap(loaded => {
    if (!loaded) {
      store.dispatch(LoadDictionary());
    }
  }), filter(loaded => loaded), take(1)).pipe(switchMap(() => of(true)), catchError(() => of(false)));
};
const storeFeature = StoreModule.forFeature('dictionary', reducers);
const effectsFeature = EffectsModule.forFeature(effects);
class DictionaryNgrxModule {
  static forRoot(config) {
    config = {
      apiUrl: 'api/dictionary',
      mapping: [{
        domainPart: '.nl/',
        language: 'nl'
      }],
      ...config
    };
    return {
      ngModule: DictionaryNgrxModule,
      providers: [importProvidersFrom(storeFeature, effectsFeature), LanguageService, {
        provide: DictionaryConfigService,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function DictionaryNgrxModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DictionaryNgrxModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: DictionaryNgrxModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DictionaryNgrxModule, [{
    type: NgModule,
    args: [{
      imports: [FromDictionaryPipe, SetValueToDictionaryPipe],
      exports: [FromDictionaryPipe, SetValueToDictionaryPipe]
    }]
  }], null, null);
})();
function ProvideDictionaryNgrx(config) {
  // using default values for missing properties
  const merged = {
    apiUrl: 'api/dictionary',
    mapping: [{
      domainPart: '.nl/',
      language: 'nl'
    }],
    ...config
  };
  return makeEnvironmentProviders([provideState('dictionary', reducers), provideEffects(effects), LanguageService, {
    provide: DictionaryConfigService,
    useValue: merged
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { DictionaryGuard, DictionaryNgrxModule, FromDictionaryPipe, LanguageService, LoadDictionary, LoadDictionaryEntriesSuccess, LoadDictionaryFail, LoadDictionarySuccess, ProvideDictionaryNgrx, SetValueToDictionaryPipe, effects, getAllDictionaryState, getDictionaryEntities, getDictionaryLoaded, getDictionaryLoading, getDictionaryState, reducers };
